import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
const About = lazy(() => import("./Pages/About"));
const BlueTea = lazy(() => import("./Pages/BlueTea"));
const Cancellation = lazy(() => import("./Pages/Cancellation"));
const Cart = lazy(() => import("./Pages/Cart"));
const Checkout = lazy(() => import("./Pages/Checkout"));
const Contact = lazy(() => import("./Pages/Contact"));
const Home = lazy(() => import("./Pages/Home"));
const Login = lazy(() => import("./Pages/Login"));
const MasalaTea = lazy(() => import("./Pages/MasalaTea"));
const HisTea = lazy(() => import("./Pages/HisTea"));
const EarlGrayTea = lazy(() => import("./Pages/EarlGrayTea"));
const MyAccount = lazy(() => import("./Pages/MyAccount"));
const PeachTea = lazy(() => import("./Pages/PeachTea"));
const PeppermintTea = lazy(() => import("./Pages/PeppermintTea"));
const Privacy = lazy(() => import("./Pages/Privacy"));
const RedTea = lazy(() => import("./Pages/RedTea"));
const ShippingPolicy = lazy(() => import("./Pages/ShippingPolicy"));
const Shop = lazy(() => import("./Pages/Shop"));
const Terms = lazy(() => import("./Pages/Terms"));
const ThankYou = lazy(() => import("./Pages/ThankYou"));
const BeforeLoginRoutes = lazy(() => import("./Routes/BeforeLoginRoutes"));
const PrivateRoutes = lazy(() => import("./Routes/PrivateRoutes"));

const renderLoader = () => (
  <img
    src="/images/spinner.gif"
    style={{ width: "200px", margin: "auto", display: "block" }}
    alt="Loading..."
  />
);
function Routes() {
  return (
    <Suspense fallback={renderLoader()}>
      <Switch>
        <Route exact path="/about-us" component={About} />
        <Route exact path="/contact-us" component={Contact} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/shop" component={Shop} />
        <Route exact path="/blue-tea" component={BlueTea} />
        <Route exact path="/red-tea" component={RedTea} />
        <Route exact path="/peach-tea" component={PeachTea} />
        <Route exact path="/masala-tea" component={MasalaTea} />
        <Route exact path="/pepperment-tea" component={PeppermintTea} />
        <Route exact path="/his-tea" component={HisTea} />
        <Route exact path="/earl-grey-tea" component={EarlGrayTea} />
        <PrivateRoutes exact path="/my-account" component={MyAccount} />
        <Route exact path="/" component={Home} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/order-placed" component={ThankYou} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/shipping-policy" component={ShippingPolicy} />
        <Route exact path="/cancellation-policy" component={Cancellation} />
        <BeforeLoginRoutes exact path="/login" component={Login} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
