import {
  GET_CARTS,
  TOTAL_CARTS,
  ADD_CART,
  EDIT_CART,
  GET_CART,
  CARTS_ERROR,
} from "../actions/types";

const initialState = {
  carts: null,
  cart: null,
  total_carts: 0,
  loading: true,
  error: {},
  cart_message: null,
};

export default function cart(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CARTS:
      return {
        ...state,
        carts: payload,
        loading: false,
        error: {},
      };

    case TOTAL_CARTS:
      return {
        ...state,
        total_carts: payload,
        loading: false,
        error: {},
      };

    case ADD_CART:
      return {
        ...state,
        cart_message: payload,
        loading: false,
        error: {},
      };
    case GET_CART:
      return {
        ...state,
        cart: payload,
        loading: false,
        error: {},
      };
    case EDIT_CART:
      return {
        ...state,
        cart_message: payload,
        loading: false,
        error: {},
      };

    case CARTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
