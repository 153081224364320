import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import cart from "./cart-reducer";
import order from "./order_reducer";

export default combineReducers({
  auth,
  alert,
  cart,
  order,
});
