import api from "../utils/api";
import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_PROFILE,
} from "./types";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/users/me");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (formData) => async (dispatch) => {
  try {
    const registeruser = {
      username: formData.email,
      email: formData.email,
      password: formData.password,
    };
    const res = await api.post("/auth/local/register", registeruser);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login User
export const login = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/auth/local", formData);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};
// Get Profile

export const updateCustomer = (formdata, id) => async (dispatch) => {
  try {
    const userData = await api.put(`/users/${id}`, formdata);

    dispatch(loadUser());
  } catch (err) {
    console.log(err);
  }
};

export const getProfile = (formData) => async (dispatch) => {
  try {
    if (formData.type === "VOLUNTEER") {
      const res = await api.get(`/volunteers/${formData.id}`);

      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });
    }
    if (formData.type === "STUDENT") {
      const res = await api.get(`/students/${formData.id}`);

      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};
export const createOrder = (formData, history, auth) => async (dispatch) => {
  try {
    // const userData = await api.post(`/orders`, formData);

    // console.log("User", userData.data);
    console.log("FormData", formData);
    const shipRoket = await axios.post(
      "https://apiv2.shiprocket.in/v1/external/auth/login",
      {
        email: "info@secretleaves.in",
        password: "Welcome@123",
      }
    );
    console.log(shipRoket.data);
    if (shipRoket.data) {
      axios.defaults.headers.Authorization = `Bearer ${shipRoket.data.token}`;
      const shipOrder = {
        order_id: formData._id,
        order_date: new Date(),
        pickup_location: "BIOSCOPE",
        billing_customer_name:
          formData.customer && formData.customer.first_name,
        billing_last_name: formData.customer && formData.customer.last_name,
        billing_address: formData.address && formData.address.address_1,
        billing_address_2: formData.address && formData.address.address_2,

        billing_city: formData.address && formData.address.city,
        billing_pincode: formData.address && formData.address.zipcode,
        billing_state: formData.address && formData.address.state,
        billing_country: "India",
        billing_email: formData.customer && formData.customer.email,
        billing_phone: formData.customer && formData.customer.phone,

        shipping_is_billing: true,

        payment_method:
          formData.payment_option === "CASH_ON_DELIVERY" ? "COD" : "Prepaid",

        sub_total: formData.total_amount,
        length: 6,
        breadth: 6,
        height: 11,
        weight: 0.035,
      };
      const Items = formData.product.map((item) => {
        const product = {
          name: item.name,
          sku: "B08QCPRLN6",
          units: item.quantity,
          selling_price: item.sale_price,
        };
        return product;
      });
      shipOrder.order_items = Items;
      const shipOrderData = await axios.post(
        `https://apiv2.shiprocket.in/v1/external/orders/create/adhoc`,
        shipOrder
      );
    }
    console.log(shipOrderData.data);
    history.push("order-placed");
    dispatch({
      type: ORDER_PLACED,
    });
  } catch (err) {
    console.log(err);
  }
};

// Logout
export const logout = () => ({ type: LOGOUT });
